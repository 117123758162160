import React from "react"
import PeopleImage from "../../images/People.png"
import { SecondaryButton } from "../Button"

const Banner = () => {
  return (
    <div
      className=" py-60 text-center bg-primary-blue bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(${PeopleImage})` }}
    >
      <div className="px-4 my-8 text-xl text-white lg:px-48">
        If your business involves building a team to service client’s needs,
        Baseline could make that process cheaper, easier and more effective.
      </div>
      <a href="https://app.baselineteams.com/register">
        <SecondaryButton>Try for free</SecondaryButton>
      </a>
    </div>
  )
}

export default Banner
