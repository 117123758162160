import React from "react";
import Layout from "../components/layout/Layout";
import ComingSoon from "../components/whoItsFor/ComingSoon";
import IndustryDemo from "../components/whoItsFor/IndustryDemo";
import IndustryDemoMobile from "../components/whoItsFor/IndustryDemoMobile";
import Banner from "../components/whoItsFor/WhoForBanner";
import { Helmet } from "react-helmet";

export default function WhoItsFor({ data }) {
  const industryDemoData = data.allIndustryDemoJson.nodes;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Baseline | Who its for</title>
        <meta
          name="description"
          content="Baseline is an efficient and cost effective resource planning tool that builds and manages the optimal teams for your projects."
        />
        <meta
          name="keywords"
          content="Saas, Cheap, Time Saving ,Team, Team Management,Mental Health,Best Team Management Tool, Best Project Management Tool,Easy to use Team Management Tool,Easy to use Project Management Tool,Monitoring profitability,Ways to improve profitability,Time management,How to improve mental health,How to build a successful team,Staffing tool,Utilization tool,HR tool or HR Software,Resource allocation,People first team creation,Hybrid workforce,Visualize department utilization,Collaborative team building,Hard and Soft skill data talent search,User friendly team management,Future workforce,Creating happy and profitable teams,How can I see burnout in my team,"
        />
        <link rel="canonical" href="https://baselineteams.com" />
      </Helmet>
      <Layout>
        <Banner />
        <IndustryDemo data={industryDemoData} />
        <IndustryDemoMobile data={industryDemoData} />
        <ComingSoon />
      </Layout>
    </>
  );
}
export const query = graphql`
  query IndustryDemoQuery {
    allIndustryDemoJson {
      nodes {
        id
        industry
        description
        imageName
      }
    }
  }
`;
