import React, { useState } from "react"
import { PrimaryButton } from "../Button"
import IndustryDemoButton from "./IndustryDemoButton"
import IndustryDemoContent from "./IndustryDemoContent"

const IndustryDemo = ({ data }) => {
  const [currentIndustrySelected, setCurrentIndustrySelected] = useState(
    "Marketing / Communications / Events"
  )
  const getDemoContent = (industry) =>
    data.find((industryDemo) => industryDemo.industry === industry)

  return (
    <div className="hidden lg:block text-center justify-center bg-off-white py-24 px-40">
      <div className="text-2xl font-medium mb-12">
        Select an industry example and see how Baseline can benefit you. 
      </div>
      <div className="mt-8">
        <div className="flex flex-row px-32">
          {data.map((industryDemo) => (
            <IndustryDemoButton
              key={industryDemo.id}
              onClick={() => setCurrentIndustrySelected(industryDemo.industry)}
              focusDemo={industryDemo.industry === currentIndustrySelected}
            >
              {industryDemo.industry}
            </IndustryDemoButton>
          ))}
        </div>
        <div>
          <IndustryDemoContent
            data={getDemoContent(currentIndustrySelected)}
          ></IndustryDemoContent>
        </div>
      </div>
      <div className="text-base text-black my-8 px-32">
        Immediately access information regarding capacity, availability, rates,
        skills and interests within your talent pool and build the best teams
        for that campaign to be launched in a few months.
      </div>
      <a href="https://app.baselineteams.com/register">
        <PrimaryButton>Sign up now</PrimaryButton>
      </a>
    </div>
  )
}

export default IndustryDemo
