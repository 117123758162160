import React from "react"

const IndustryDemoButton = ({ children, onClick, focusDemo }) => {
  const classNames = focusDemo
    ? "flex-1 mx-4 p-6 shadow-md rounded bg-green text-white text-lg font-semibold w-16 break-words focus:outline-none "
    : "flex-1 mx-4 p-6 bg-off-white text-black text-lg font-semibold active:bg-green active:text-white w-16 break-words focus:outline-none"
  return (
    <button className={classNames} onClick={onClick}>
      {children}
    </button>
  )
}
export default IndustryDemoButton
