import React from "react"

const IndustryDemoContent = ({ data }) => {
  return (
    <div className="flex flex-col px-4 mt-12 lg:mt-4">
      <div className="text-black text-lg font-semibold lg:hidden">
        {data.industry}
      </div>
      <div className="text-black text-lg font-light font-light mt-4">
        {data.description}
      </div>

      <img
        className="mt-4 w-full h-auto"
        src={require(`../../images/${data.imageName}.png`)}
        alt="industry demo content"
      />
    </div>
  )
}
export default IndustryDemoContent
