import React from "react"
import { PrimaryButton } from "../Button"
import IndustryDemoContent from "./IndustryDemoContent"

const IndustryDemoMobile = ({ data }) => {
  return (
    <div className="text-center justify-center bg-off-white py-24 px-4 lg:hidden">
      <div className="text-2xl font-medium">
        See how Baseline can benefit different industries.
      </div>
      <div className="mt-0 px-0">
        <div className="flex flex-col">
          {data.map((industryDemo) => (
            <IndustryDemoContent
              key={industryDemo.id}
              data={industryDemo}
            ></IndustryDemoContent>
          ))}
        </div>
      </div>
      <div className="text-base text-black my-8">
        Immediately access information regarding capacity, availability, rates,
        skills and interests within your talent pool and build the best teams
        for that campaign to be launched in a few months.
      </div>
      <a href="https://app.baselineteams.com/register">
        <PrimaryButton>Sign up now</PrimaryButton>
      </a>
    </div>
  )
}

export default IndustryDemoMobile
