import React from "react"
import { SecondaryButton } from "../Button"
import FrontlineTeaser from "../../images/FrontlineTeaser.png"

const ComingSoon = () => {
  return (
    <div className="justify-center text-center bg-navy text-white py-24 px-4 lg:px-40">
      <div className="text-2xl font-medium mb-8 text-left">Beyond Baseline</div>
      <img
        className="px-4 lg:px-16"
        src={FrontlineTeaser}
        alt="frontline teaser"
      ></img>
      <div className="text-base  mt-8 mb-12">
        Here at Baseline, we believe that building the right team makes all the
        difference. That’s why we’re hard at work developing Frontline, a
        version of Baseline for the NGO sector, so that emergency response
        organizations can quickly and efficiently staff their projects where
        they’re needed most. 
        <br />
        <br />
        We’re still in the early stages of development and are looking for
        potential NGO partners to work toward this vision. If you’d like to find
        out more, please drop us a line, we’d love to have a conversation about
        it.
      </div>
      <SecondaryButton>
        <a href="/contact-us/">Get in touch</a>
      </SecondaryButton>
    </div>
  )
}

export default ComingSoon
